import React from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faGlobe } from "@fortawesome/free-solid-svg-icons";

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const glow = keyframes`
  0% { box-shadow: 0 0 5px rgba(74, 0, 224, 0.5); }
  50% { box-shadow: 0 0 20px rgba(74, 0, 224, 0.8); }
  100% { box-shadow: 0 0 5px rgba(74, 0, 224, 0.5); }
`;

const Card = styled.div`
  width: 335px;
  height: 500px;
  background-color: ${({ theme }) => theme.card};
  border-radius: 10px;
  box-shadow: rgba(103, 92, 230, 0.15) 0px 5px 35px;
  overflow: hidden;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  border: 2px solid #4a00e0;
  gap: 15px;
  transition: all 0.3s ease-in-out;
  filter: brightness(1.1);

  &:hover {
    animation: ${float} 3s ease-in-out infinite;
    box-shadow: 5px 5px 0px 0px ${({ theme }) => theme.primary};
    filter: brightness(1.3);
    border: 2.3px solid ${({ theme }) => theme.text_secondary + 70};
  }
`;

const Image = styled.img`
  width: 100%;
  height: 180px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
  box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;

  ${Card}:hover & {
    transform: scale(1.05);
  }
`;

const Tags = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 4px;
`;

const Tag = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary};
  background-color: ${({ theme }) => theme.primary + 15};
  padding: 2px 8px;
  border-radius: 10px;
`;

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 0px 2px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Date = styled.div`
  font-size: 12px;
  margin-left: 2px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary};
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

const Description = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 90};
  overflow: hidden;
  margin-top: 8px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const Members = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

const Avatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-left: -7px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 1.7px solid grey;
  transition: all 0.3s ease-in-out;

  ${Card}:hover & {
    transform: translateY(-5px);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

const Button = styled.a`
  color: ${({ theme }) => theme.text_secondary};
  text-decoration: none;
  margin-top: 0.5rem;
  font-weight: 600;
  text-align: center;
  width: calc(50% - 5px);
  padding: 10px 10px;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.primary};
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      ${({ theme }) => theme.primary + 30},
      transparent
    );
    transition: all 0.4s;
  }

  &:hover {
    /* background-color: ${({ theme }) => theme.primary + 90}; */
    color: ${({ theme }) => theme.text_primary};
    border: 2px solid ${({ theme }) => theme.text_secondary};
    animation: ${glow} 1.5s infinite;

    &:before {
      left: 100%;
    }
  }
`;

const ProjectCard = ({ project }) => {
  if (!project) {
    return null; // or return a placeholder/loading component
  }

  return (
    <Card>
      <Image src={project.image} alt={project.title || 'Project Image'} />
      <Tags>
        {project.tags && project.tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </Tags>
      <Details>
        <Title>{project.title || 'Untitled Project'}</Title>
        <Date>{project.date || 'No date provided'}</Date>
        <Description>{project.description || 'No description available'}</Description>
      </Details>
      <Members>
        {project.member && project.member.map((member) => (
          <Avatar src={member.img} alt={member.name || 'Team Member'} key={member.id} />
        ))}
      </Members>
      <ButtonContainer>
        <Button href={project.github} target="_blank" rel="noopener noreferrer">
          Github <FontAwesomeIcon icon={faCode} style={{ marginLeft: "5px" }} />
        </Button>
        <Button href={project.webapp} target="_blank" rel="noopener noreferrer">
          Website <FontAwesomeIcon icon={faGlobe} style={{ marginLeft: "5px" }} />
        </Button>
      </ButtonContainer>
    </Card>
  );
};

export default ProjectCard;
